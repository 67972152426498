import {
  Box,
  Center,
  chakra,
  HStack,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import React from 'react';
import {
  ArticleStatus,
  Author,
  createArticleImageRelativeUrl,
  createArticleRelativeUrl,
} from '../common/libs/article';
import { DiscloseScope } from '../common/libs/discloseScope';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { createProfileImageUrl, createUserName } from '../common/libs/user';
import { ArticleNameWithIcon } from '../pages';
import ArticleMetrics from './ArticleMetrics';
import { ArticleBadge, BadgeType } from './ArticleSummary';

export type WeeklyRankingArticleHeader = {
  articleId: string;
  name: string | null;
  description: string | null;
  status: ArticleStatus | null;
  topImageM: string | null;
  sessionCount: number;
  likeCount: number;
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
  author: Author;
  discloseScope?: DiscloseScope | null;
};

export type Props = WeeklyRankingArticleHeader & {
  gaEventLabel?: string;
  className?: string;
  badgeType?: BadgeType;
};

/**
 * スライド画像
 *
 * 変換完了のステータスではない場合、ステータスに応じた画像を表示する。
 */
function ArticleImage({
  articleId,
  status,
  topImageM,
  updateDate,
  alt,
}: {
  articleId: string;
  status: ArticleStatus | null;
  topImageM: string | null;
  updateDate: string;
  alt: string | null;
}) {
  let imageUrl: string | null;
  const updateDateEpochMilliseconds = new Date(updateDate).getTime();

  switch (status) {
    case 'DONE':
      imageUrl = createArticleImageRelativeUrl(articleId, topImageM, {
        version: updateDateEpochMilliseconds,
      });
      break;
    case 'CONVERTING':
      imageUrl = '/images/placeholder_article_converting.png';
      break;
    case 'DELETED':
      imageUrl = '/images/placeholder_article_deleted.png';
      break;
    case 'ERROR':
    default:
      imageUrl = '/images/placeholder_article_error.png';
      break;
  }
  return (
    <NextImage
      src={imageUrl || ''}
      alt={alt || ''}
      layout="fill"
      objectFit="contain"
    />
  );
}

/**
 * 投稿者のプロフィール画像
 *
 * プロフィール画像未設定または画像取得失敗時にはデフォルト画像を表示する。
 */
function AuthorProfileImage({
  author,
}: {
  author: WeeklyRankingArticleHeader['author'];
}) {
  let profileImageUrl: string | undefined = undefined;
  if (!author.profilePhotoS3Key && !author.idBaseProfileUrl) {
    profileImageUrl = '/images/avatar_default_gray.png';
  } else {
    profileImageUrl = createProfileImageUrl(author.userId, {
      version: author.updateDateEpochMilliseconds,
    });
    if (author.idBaseProfileUrl) {
      profileImageUrl += `&idurl=${encodeURIComponent(
        author.idBaseProfileUrl,
      )}`;
    }
  }
  const fullName = createUserName(author);
  return (
    <Box pos="relative" w={8} h={8} borderRadius="full" overflow="hidden">
      <NextImage
        src={profileImageUrl}
        alt={fullName}
        layout="fill"
        objectFit="cover"
      />
    </Box>
  );
}

/**
 * 説明文表示
 *
 * 最大2行表示で、表示しきれない場合はellipsisを表示する。
 * 横幅が狭いデバイス（スマホ）では表示しない。
 */
function ArticleDescription({ description }: { description: string | null }) {
  return (
    <Box h="2.8em" display={['none', 'block']}>
      <Text fontSize="md" noOfLines={2} lineHeight={1.3}>
        {description || ''}
      </Text>
    </Box>
  );
}

function WeeklyRankingArticleSummary({
  articleId,
  name,
  description,
  status,
  topImageM,
  sessionCount,
  likeCount,
  updateDate,
  author,
  className,
  discloseScope = undefined,
  badgeType,
}: Props) {
  const metricsViewType = useBreakpointValue<'list' | 'list-mobile'>(
    {
      base: 'list-mobile',
      sm: 'list',
    },
    {
      fallback: 'list',
    },
  );
  const imageHeight = useBreakpointValue({
    base: 90,
    sm: 140,
  });
  return (
    <Box
      boxShadow="0 0 8px rgba(0,0,0,0.08)"
      borderRadius="lg"
      overflow="hidden"
      className={className}
    >
      <HStack align="flex-start" spacing={4}>
        <Center
          flexGrow={0}
          flexShrink={0}
          flexBasis={[120, 180]}
          h={imageHeight}
          bg="blackAlpha.300"
          borderWidth={1}
          borderColor="gray.100"
          borderRadius="md"
          overflow="hidden"
          pos="relative"
          order={{
            base: 2,
            sm: 1,
          }}
        >
          {/* <LazyLoad height={imageHeight} once> */}
          <ArticleImage
            articleId={articleId}
            status={status}
            topImageM={topImageM}
            updateDate={updateDate}
            alt={name}
          />
          {/* </LazyLoad> */}
          {!!badgeType && (
            <ArticleBadge
              badgeType={badgeType}
              pos="absolute"
              top={1}
              right={1}
            />
          )}
        </Center>
        <Box
          flex="1"
          minW={0}
          h={imageHeight}
          order={{
            base: 1,
            sm: 2,
          }}
        >
          <ArticleNameWithIcon name={name} discloseScope={discloseScope} />
          <ArticleDescription description={description || ''} />
          <HStack align="center" spacing={4} mt={2}>
            <HStack
              spacing={2}
              flex={1}
              display={{
                base: 'none', // モバイル表示の時はArticleMetricsで表示するため
                sm: 'block',
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <AuthorProfileImage author={author} />
                <Text
                  fontSize="sm"
                  lineHeight={1.3}
                  display="inline-block"
                  noOfLines={1}
                >
                  {createUserName(author)}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <ArticleMetrics
                author={author}
                likeCount={likeCount}
                sessionCount={sessionCount}
                updateDateIsoString={updateDate}
                viewType={metricsViewType}
              />
            </HStack>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
}

/**
 * リンク化されたWeeklyRankingArticleSummaryコンポーネント
 *
 * GAイベント情報が指定されていた場合、リンク押下時にGAイベントを送信する。
 */
function LinkifiedWeeklyRankingArticleSummary(props: Props) {
  const { articleId, gaEventLabel } = props;
  const articleUrl = createArticleRelativeUrl(articleId);

  return (
    <NextLink href={articleUrl} passHref>
      <Link
        display="inline-block"
        color="inherit"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={() =>
          gaEventLabel && sendAnalyticsEvent('slide', 'click', gaEventLabel)
        }
        w="100%"
      >
        <WeeklyRankingArticleSummary {...props} />
      </Link>
    </NextLink>
  );
}

/**
 * メモ化されたWeeklyRankingArticleSummaryコンポーネント
 *
 * 不必要な再renderを防いでパフォーマンス向上を見込む。
 * 以下のプロパティのいずれかが変化した場合のみ再renderが必要だと判断する。
 * - articleId
 */
const MemoizedArticleSummary = React.memo(
  LinkifiedWeeklyRankingArticleSummary,
  (prevProps, nextProps) => prevProps.articleId === nextProps.articleId,
);

export default chakra(MemoizedArticleSummary);
