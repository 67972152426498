import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import isMobileDevice from 'ismobilejs';
import type { GetServerSideProps } from 'next';
import NextHead from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getPublicRuntimeConfig } from '../common/config';
import {
  ArticleStatus,
  shouldNotShow,
  createArticleRelativeUrl,
} from '../common/libs/article';
import { DiscloseScope } from '../common/libs/discloseScope';
import {
  AnalyticsEventProps,
  sendAnalyticsEvent,
} from '../common/libs/googleAnalytics';
import { createProfileImageUrl, createUserName } from '../common/libs/user';
import { NextPageWithLayout } from '../common/types';
import ArticlesGrid, { ArticleHeader } from '../components/ArticlesGrid';
import { FaLock } from '../components/Icons';
import LayoutWithSidebar, {
  layoutWithSidebarLgPaddingTop,
} from '../components/LayoutWithSidebar';
import MobileAppDownloadBar from '../components/MobileAppDownloadBar';
import PostMotivateBanner from '../components/PostMotivateBanner';
import RecommendedArticlesByViewHistories from '../components/RecommendedArticlesByViewHistories';
import SlideRequests from '../components/SlideRequests';
import UserRecentlyViewedArticles, {
  UserRecentlyViewedArticle,
} from '../components/UserRecentlyViewedArticles';
import WeeklyRankingArticleSummary, {
  WeeklyRankingArticleHeader,
} from '../components/WeeklyRankingArticleSummary';
import * as date from '../server/libs/date';
import { getGaCid } from '../server/libs/googleAnalytics';
import { fetchAccountIntegrationProfiles } from '../server/libs/id';
import prisma from '../server/libs/prisma';
import withAuth from '../server/libs/withAuth';
import HorizontalScrollList from '../components/HorizontalScrollList';
import {
  ArticleBadge,
  ArticleSummaryImage,
  AuthorName,
  AuthorProfileImage,
  BadgeType,
} from '../components/ArticleSummary';
import ArticleMetrics from '../components/ArticleMetrics';
import ViewAllLInk from '../components/ViewAllLInk';
import useApiClient from '../hooks/useApiClient';
import monitoring from '../common/libs/monitoring';
import {
  POPULAR_AUTHOR_MAX_FETCH_NUM,
  TOP_SPECIAL_ELEMENTS,
} from '../common/constants';

const BADGE_SHOW_HOUR = 48;
const MAX_POPULAR_TAGS_COUNT = 30;
const MIN_VIEW_HISTORY_COUNT = 6;
export const MAX_ARTICLE_COUNT_WHEN_MOBILE = 4;
export const MIN_TOP_ARTICLE_COUNT = 9; // 4Kディスプレイ時に初期表示が9件のため

type Tag = {
  id: number;
  name: string;
};

type Author = {
  userId: number;
  userName: string | null;
  lastName: string | null;
  firstName: string | null;
  hospital: string | null;
  departmentName: string;
  hasProfileImage: boolean;
  idBaseProfileUrl: string | null;
  updateDateEpochMilliseconds: number;
};

type PageContext = {
  featuredArticles: ArticleHeader[];
  weeklyRankingArticles: WeeklyRankingArticleHeader[];
  popularTags: Tag[];
  popularAuthors: Author[];
  userViewedArticleCount: number | null;
  userRecentlyViewedArticles: UserRecentlyViewedArticle[] | null;
};

function PageHead() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return (
    <NextHead>
      <link rel="canonical" href={slideWebBaseUrl} />

      <meta property="og:url" content={slideWebBaseUrl} key="og:url" />
    </NextHead>
  );
}

export function SectionHeading({
  href,
  withLink,
  onClick,
  children,
}: {
  href?: string;
  withLink?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return withLink ? (
    <Flex
      mb={4}
      alignItems="baseline"
      justifyContent={{ base: 'space-between', sm: 'flex-start' }}
    >
      <Heading fontSize="xl">{children}</Heading>
      <ViewAllLInk
        href={href || ''}
        onClick={onClick}
        additionalButtonProps={{
          w: 'auto',
          pl: 4,
          pr: 0,
          mt: 0,
        }}
      />
    </Flex>
  ) : (
    <Heading fontSize="xl" mb={4}>
      {children}
    </Heading>
  );
}

/**
 * 閲覧履歴
 *
 * 現在不使用。将来的に復活させることを考慮して残しておく。
 */
function UserRecentlyViewedArticlesSection({
  viewedArticleCount,
  viewedArticles,
}: {
  viewedArticleCount: number | null;
  viewedArticles: UserRecentlyViewedArticle[] | null;
}) {
  if (!viewedArticleCount || !viewedArticles || viewedArticles.length === 0) {
    return null;
  }

  const onClickMoreHistory = () => {
    sendAnalyticsEvent('view_history', 'click', 'from_top');
  };

  return (
    <Box w="full">
      {/* HACK: 画面横幅いっぱいまで背景色変更するためのCSSテクニック */}
      <Box w="100vw" m="0 calc(50% - 50vw)" bg="blackAlpha.50" py={4}>
        <Container>
          <HStack wrap="wrap" spacing={0} justify="space-between">
            <Text fontWeight="bold" mb={2}>
              閲覧履歴
            </Text>
            {viewedArticles && viewedArticleCount > MIN_VIEW_HISTORY_COUNT && (
              <Box>
                <NextLink href={'/mypage/view-history'} passHref>
                  <Link
                    display="inline-block"
                    color="inherit"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    onClick={onClickMoreHistory}
                  >
                    <HStack pr={0} mb={2}>
                      <Text fontSize="sm" fontWeight="bold">
                        すべて見る
                      </Text>
                      <ChevronRightIcon color="brand.500" w={6} h={6} />
                    </HStack>
                  </Link>
                </NextLink>
              </Box>
            )}
          </HStack>
          <UserRecentlyViewedArticles
            articles={viewedArticles}
            onClickArticle={() => {
              sendAnalyticsEvent(
                'slide',
                'click',
                'user_recently_viewed_from_top',
              );
            }}
          />
        </Container>
      </Box>
    </Box>
  );
}

export function getArticleBadgeType<
  T extends ArticleHeader | WeeklyRankingArticleHeader,
>(articles: T[]): T[] {
  return articles.map((article) => {
    const createDate = new Date(article.createDate);
    const updateDate = new Date(article.updateDate);
    if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        createDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'new',
      };
    } else if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        updateDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'updated',
      };
    }
    return article;
  });
}

function FeaturedArticles({
  articles,
  isMobile,
}: {
  articles: ArticleHeader[];
  isMobile: boolean;
}) {
  if (articles.length === 0) {
    return null;
  }

  const gaEventLabel = 'featured_from_top';
  const articlesWithBadgeType: ArticleHeader[] = getArticleBadgeType(articles);

  return (
    <Box>
      <SectionHeading>注目のスライド</SectionHeading>
      {isMobile ? (
        <ArticlesGrid
          articles={articlesWithBadgeType}
          gaEventLabel={gaEventLabel}
          additionalGaEvent={{
            category: 'featured',
            action: 'click',
            label: null, // ArticlesGrid内でarticle_idを設定する
          }}
          isMobile={isMobile}
        />
      ) : (
        <HorizontalScrollList>
          {articlesWithBadgeType.map((article) => (
            <ArticleList
              key={article.articleId}
              article={article}
              isMobile={isMobile}
              gaEvent={{
                category: 'slide',
                action: 'click',
                label: gaEventLabel,
              }}
            />
          ))}
        </HorizontalScrollList>
      )}
    </Box>
  );
}

function WeeklyRankingArticles({
  articles,
  isMobile,
}: {
  articles: WeeklyRankingArticleHeader[];
  isMobile: boolean;
}) {
  if (articles.length === 0) {
    return null;
  }

  const bgGradient = [
    'linear(to-r, yellow.400, yellow.500)',
    'linear(to-r, gray.300, gray.400)',
    'linear(to-r, yellow.700, yellow.800)',
    'gray',
  ];
  const viewAllLinkOptions = {
    href: '/weekly-ranking-articles',
    onClick: () =>
      sendAnalyticsEvent('weekly_ranking', 'click', 'weekly_ranking_from_top'),
  };
  const articlesWithBadgeType: WeeklyRankingArticleHeader[] =
    getArticleBadgeType(articles);

  return (
    <Box>
      <SectionHeading withLink={!isMobile} {...viewAllLinkOptions}>
        週間スライドランキング
      </SectionHeading>
      {isMobile ? (
        <>
          <SimpleGrid spacing={4} w="full">
            {articlesWithBadgeType.map((article, i) => (
              <Box pos="relative" key={article.articleId}>
                <WeeklyRankingArticleSummary
                  key={article.articleId}
                  {...article}
                  gaEventLabel={`weekly_ranking_${i + 1}_from_top`}
                  updateDate={article.updateDate}
                  p={2}
                />
              </Box>
            ))}
          </SimpleGrid>
          <ViewAllLInk {...viewAllLinkOptions} />
        </>
      ) : (
        <HorizontalScrollList>
          {articlesWithBadgeType.map((article, i) => (
            <Fragment key={article.articleId}>
              <ArticleList
                article={article}
                isMobile={isMobile}
                gaEvent={{
                  category: 'slide',
                  action: 'click',
                  label: `weekly_ranking_${i + 1}_from_top`,
                }}
              />
              <Center
                pos="absolute"
                top={1}
                left={1}
                bg="gray.500"
                bgGradient={bgGradient[i]}
                borderRadius="full"
                color="white"
                fontSize="xs"
                w={5}
                h={5}
              >
                {i + 1}
              </Center>
            </Fragment>
          ))}
        </HorizontalScrollList>
      )}
    </Box>
  );
}

export function ArticleNameWithIcon({
  name,
  discloseScope = undefined,
}: {
  name: string | null;
  discloseScope?: DiscloseScope | null;
}) {
  const length = '0.875rem';
  const lockStyleProps = {
    minWidth: length,
    minHeight: length,
  };

  return (
    <Box display="inline-flex" gap={1} mt={2}>
      {discloseScope && discloseScope === 'PARTIAL' && (
        <FaLock color="#D6BF51" style={lockStyleProps} />
      )}
      <Text
        fontSize={{ base: 'sm', sm: 'md' }}
        fontWeight={'bold'}
        noOfLines={{ base: 2, sm: 3 }}
        lineHeight="1.25"
        maxW="100%"
        wordBreak="break-all"
      >
        {name}
      </Text>
    </Box>
  );
}

export function ArticleList({
  article,
  isMobile,
  gaEvent,
}: {
  article:
    | (ArticleHeader & { badgeType?: BadgeType })
    | (WeeklyRankingArticleHeader & { badgeType?: BadgeType });
  isMobile: boolean;
  gaEvent: AnalyticsEventProps;
}) {
  return (
    <Link
      href={createArticleRelativeUrl(article.articleId)}
      isExternal={createArticleRelativeUrl(article.articleId).startsWith(
        'http',
      )}
      color="linkText"
      _hover={{ textDecoration: 'none' }}
      onClick={() =>
        sendAnalyticsEvent(gaEvent.category, gaEvent.action, gaEvent.label)
      }
      style={{
        width: '280px',
      }}
    >
      <Center mb={2}>
        <Box
          borderRadius="10px"
          overflow="hidden"
          w="280px"
          h="147px"
          bg="blackAlpha.300"
          borderBottomColor="gray.50"
          borderBottomWidth={1}
          position={'relative'}
        >
          <ArticleSummaryImage
            articleId={article.articleId}
            status={article.status}
            topImageM={article.topImageM}
            updateDate={article.updateDate}
            alt={article.name}
          />
          {!!article.badgeType && (
            <ArticleBadge
              badgeType={article.badgeType}
              pos="absolute"
              top={1}
              right={1}
            />
          )}
        </Box>
      </Center>
      <VStack spacing={2} align="start">
        <ArticleNameWithIcon
          name={article.name}
          discloseScope={article.discloseScope}
        />
        <HStack spacing={2} py={1} h="3em">
          <AuthorProfileImage author={article.author} />
          <AuthorName
            name={createUserName(article.author)}
            isOfficial={article.author.isOfficialAccount ?? false}
          />
        </HStack>
        <Box>
          <ArticleMetrics
            likeCount={article.likeCount}
            sessionCount={article.sessionCount}
            updateDateIsoString={article.updateDate}
            viewType={isMobile ? 'list-mobile' : 'list'}
          />
        </Box>
      </VStack>
    </Link>
  );
}

function NewArticles({ isMobile }: { isMobile: boolean }) {
  const { apiClient } = useApiClient();
  const [articles, setArticles] = useState<ArticleHeader[]>([]);
  const [offset, setOffset] = useState(0);
  const [totalArticlesCount, setTotalArticlesCount] = useState(1);
  const limit = isMobile
    ? MAX_ARTICLE_COUNT_WHEN_MOBILE
    : MIN_TOP_ARTICLE_COUNT;
  let fetching = false;

  const callNewArticlesApi = async () => {
    try {
      if (fetching || offset > totalArticlesCount) {
        return;
      }
      fetching = true;
      const res = await apiClient(
        `/api/article/new-articles?offset=${offset}&limit=${limit}`,
      );
      if (res.ok) {
        const { newArticles, nextOffset, totalArticlesCount } =
          await res.json();
        setArticles((currentData) => [...currentData, ...newArticles]);
        setOffset(nextOffset);
        setTotalArticlesCount(totalArticlesCount);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '新着スライドの取得に失敗しました。',
      });
    } finally {
      fetching = false;
    }
  };

  useEffect(() => {
    callNewArticlesApi();
  }, []);

  if (articles.length === 0) {
    return null;
  }

  const gaEventLabel = 'new_from_top';
  const viewAllLinkOptions = {
    href: '/articles',
    onClick: () => sendAnalyticsEvent('slides', 'click', gaEventLabel),
  };

  const articlesWithBadgeType: ArticleHeader[] = getArticleBadgeType(articles);

  return (
    <Box>
      <SectionHeading withLink={!isMobile} {...viewAllLinkOptions}>
        新着のスライド
      </SectionHeading>
      {isMobile ? (
        <>
          <ArticlesGrid
            articles={articlesWithBadgeType}
            gaEventLabel={gaEventLabel}
            isMobile={isMobile}
          />
          <ViewAllLInk {...viewAllLinkOptions} />
        </>
      ) : (
        <HorizontalScrollList
          swiperOptions={{
            onReachEnd() {
              callNewArticlesApi();
            },
          }}
        >
          {articlesWithBadgeType.map((article) => (
            <ArticleList
              key={article.articleId}
              article={article}
              isMobile={isMobile}
              gaEvent={{
                category: 'slide',
                action: 'click',
                label: gaEventLabel,
              }}
            />
          ))}
        </HorizontalScrollList>
      )}
    </Box>
  );
}

function CareerTag() {
  const onClickTag = () => {
    sendAnalyticsEvent('slide', 'index', 'career_tag');
  };
  const p = new URLSearchParams();
  p.set('q', 'キャリア');

  return (
    <NextLink href={`/search?${p.toString()}`} passHref>
      <Link
        display="inline-block"
        color="inherit"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={onClickTag}
        ml={'4px'}
      >
        <Text
          px={3}
          py={1.5}
          fontSize="sm"
          fontWeight="bold"
          color="textSecondary"
          borderColor="textDisabled"
          borderWidth={1}
          borderRadius="full"
        >{`#キャリア`}</Text>
      </Link>
    </NextLink>
  );
}

function SpecialContents() {
  return (
    <Box>
      <SectionHeading
        href="/special"
        withLink
        onClick={() =>
          sendAnalyticsEvent('special', 'click', 'special_from_top')
        }
      >
        特集&#12539;コラボ企画
      </SectionHeading>
      <HorizontalScrollList>
        {TOP_SPECIAL_ELEMENTS.map((element) => (
          <Link
            key={element.trackingId}
            href={element.linkUrl}
            isExternal={element.linkUrl.startsWith('http')}
            color="linkText"
            _hover={{ textDecoration: 'none' }}
            onClick={() =>
              sendAnalyticsEvent('special', 'click', element.trackingId)
            }
            style={{
              width: '280px',
            }}
          >
            <Box
              borderRadius="10px"
              overflow="hidden"
              w="280px"
              h="147px"
              bg="blackAlpha.300"
            >
              <NextImage
                src={element.imgSrc}
                width={280}
                height={147}
                alt={element.description ?? ''}
              />
            </Box>
            <Text fontSize={'md'} fontWeight={'bold'} mt={2}>
              {element.description}
            </Text>
          </Link>
        ))}
      </HorizontalScrollList>
    </Box>
  );
}

function PopularTags({ tags }: { tags: Tag[] }) {
  const onClickTag = () => {
    sendAnalyticsEvent('slide', 'index', 'frequently_used_tag');
  };
  return (
    <Box>
      <HorizontalScrollList
        swiperOptions={{
          spaceBetween: 4,
          navigation: false,
        }}
      >
        {tags.map((tag, i) => (
          <Fragment key={tag.id}>
            <NextLink href={`/tagsearch/${tag.id}`} passHref>
              <Link
                display="inline-block"
                color="inherit"
                _hover={{
                  textDecoration: 'none',
                }}
                onClick={onClickTag}
              >
                <Text
                  px={3}
                  py={1.5}
                  fontSize="sm"
                  fontWeight="bold"
                  color="textSecondary"
                  borderColor="textDisabled"
                  borderWidth={1}
                  borderRadius="full"
                >
                  {`#${tag.name}`}
                </Text>
              </Link>
            </NextLink>
            {i === tags.length - 1 && <CareerTag />}
          </Fragment>
        ))}
      </HorizontalScrollList>
    </Box>
  );
}

function PopularAuthors({
  authors,
  isMobile,
}: {
  authors: Author[];
  isMobile: boolean;
}) {
  const hoverBgColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.100');
  const gaEvent = () =>
    sendAnalyticsEvent('profile', 'click', 'popular_author_from_top');

  const authorsForView = useMemo(
    () =>
      authors.map((author) => {
        let profileImageUrl: string | undefined = undefined;
        if (author.hasProfileImage) {
          profileImageUrl = createProfileImageUrl(author.userId, {
            version: author.updateDateEpochMilliseconds,
          });
          if (author.idBaseProfileUrl) {
            profileImageUrl += `${
              author.updateDateEpochMilliseconds ? '&' : '?'
            }idurl=${encodeURIComponent(author.idBaseProfileUrl)}`;
          }
        }
        return {
          ...author,
          profileUrl: `/profile/${author.userId}`,
          profileImageUrl,
          fullName: createUserName(author),
          affiliation: (() => {
            if (author.hospital && author.departmentName) {
              return `${author.hospital}\u00A0/\u00A0${author.departmentName}`;
            } else if (author.hospital) {
              return author.hospital;
            } else if (author.departmentName) {
              return author.departmentName;
            } else {
              return undefined;
            }
          })(),
        };
      }),
    [authors],
  );

  if (authors.length === 0) {
    return null;
  }

  return (
    <Box>
      <SectionHeading>今注目の投稿者</SectionHeading>
      {isMobile ? (
        <VStack>
          {authorsForView.map((author, i) => (
            <NextLink key={i} href={author.profileUrl} passHref>
              <Link
                display="inline-block"
                color="inherit"
                py={2}
                w={'100%'}
                _hover={{
                  textDecoration: 'none',
                  bgColor: hoverBgColor,
                }}
                onClick={gaEvent}
              >
                <VStack>
                  <HStack w={'100%'}>
                    <Box
                      pos="relative"
                      w="32px"
                      h="32px"
                      borderRadius="full"
                      overflow="hidden"
                      flexShrink={0}
                    >
                      <NextImage
                        src={
                          author.profileImageUrl ||
                          '/images/avatar_default_gray.png'
                        }
                        alt={author.fullName}
                        layout="fill"
                        objectFit="cover"
                      />
                    </Box>
                    <Text
                      fontSize="sm"
                      fontWeight={'bold'}
                      noOfLines={2}
                      wordBreak="break-all"
                    >
                      {author.fullName}
                    </Text>
                    {/* TODO: フォローボタンの設置 */}
                  </HStack>
                  <Text
                    fontSize="xs"
                    noOfLines={3}
                    w={'100%'}
                    color={'textSecondary'}
                  >
                    {author.affiliation}
                  </Text>
                </VStack>
              </Link>
            </NextLink>
          ))}
        </VStack>
      ) : (
        <HorizontalScrollList
          swiperOptions={{
            spaceBetween: 16,
          }}
          sliderOptions={{
            style: {
              width: '200px',
              height: '126px', // TODO: フォローボタン設置に合わせて高さ調整
              border: '1px solid var(--Border-Light, rgba(216, 216, 219, 1))',
              borderRadius: '4px',
            },
          }}
        >
          {authorsForView.map((user) => (
            <NextLink key={user.userId} href={user.profileUrl} passHref>
              <Link
                display={'inline-block'}
                w={'100%'}
                h={'100%'}
                p={4}
                color="inherit"
                _hover={{
                  textDecoration: 'none',
                  bgColor: hoverBgColor,
                }}
                onClick={gaEvent}
              >
                <VStack alignItems={'left'}>
                  <HStack>
                    <Box
                      pos="relative"
                      w="32px"
                      h="32px"
                      borderRadius="full"
                      overflow="hidden"
                      flexShrink={0}
                    >
                      <NextImage
                        src={
                          user.profileImageUrl ||
                          '/images/avatar_default_gray.png'
                        }
                        alt={user.fullName}
                        layout="fill"
                        objectFit="cover"
                      />
                    </Box>
                    <Text
                      fontSize="sm"
                      fontWeight={'bold'}
                      noOfLines={2}
                      wordBreak="break-all"
                    >
                      {user.fullName}
                    </Text>
                  </HStack>
                  <Text
                    fontSize="xs"
                    noOfLines={3}
                    w={'100%'}
                    color={'textSecondary'}
                  >
                    {user.affiliation}
                  </Text>
                  {/* TODO: フォローボタンの設置 */}
                </VStack>
              </Link>
            </NextLink>
          ))}
        </HorizontalScrollList>
      )}
    </Box>
  );
}

function BannerContents() {
  const { qaWebBaseUrl } = getPublicRuntimeConfig();

  return (
    <Center
      gap={{ base: 4, md: 16 }}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Link
        href="/docs/community-guidelines"
        onClick={() => {
          sendAnalyticsEvent('community_guideline', 'click', 'from_top');
        }}
        pt={'6px'}
      >
        <NextImage
          alt="コミュニティガイドライン"
          src={'/images/community_guideline.png'}
          width={'468'}
          height={'120'}
        />
      </Link>
      <Link
        href={`${qaWebBaseUrl}/auth/redirect?type=slide-template`}
        isExternal
        onClick={() => {
          sendAnalyticsEvent('slide_template', 'click', 'from_top');
        }}
        pt={'6px'}
      >
        <NextImage
          alt="Antaa Slideテンプレート集"
          src={'/images/slide_template.png'}
          width={'468'}
          height={'120'}
        />
      </Link>
    </Center>
  );
}

const HomePage: NextPageWithLayout<PageContext> = ({
  featuredArticles,
  weeklyRankingArticles,
  popularTags,
  popularAuthors,
  userViewedArticleCount,
  userRecentlyViewedArticles,
}) => {
  const isMobile =
    typeof window !== 'undefined' && isMobileDevice(navigator.userAgent).any;

  return (
    <>
      <PageHead />
      <VStack spacing={12} align="stretch">
        <PopularTags tags={popularTags} />
        <SpecialContents />
        <NewArticles isMobile={isMobile} />
        <FeaturedArticles articles={featuredArticles} isMobile={isMobile} />
        <BannerContents />
        <RecommendedArticlesByViewHistories isMobile={isMobile} />
        <WeeklyRankingArticles
          articles={weeklyRankingArticles}
          isMobile={isMobile}
        />
        <PopularAuthors authors={popularAuthors} isMobile={isMobile} />
        <PostMotivateBanner gaEventLabel="from_top" />
        <MobileAppDownloadBar />
      </VStack>
    </>
  );
};

const sidebarContent = (
  <Box
    w={{
      base: '100%',
      lg: '320px',
      xl: '360px',
    }}
    display={{
      // リクエストスライドページ追加時点ではPC画面以外サイドバー非表示
      base: 'none',
      lg: 'block',
    }}
  >
    <SlideRequests marginTop={layoutWithSidebarLgPaddingTop} />
  </Box>
);

HomePage.getLayout = function getLayout(page: React.ReactNode) {
  return (
    <LayoutWithSidebar sidebarContent={sidebarContent}>
      {page}
    </LayoutWithSidebar>
  );
};

export default HomePage;

/**
 * Featuredコンテンツ一覧を取得
 */
async function findFeaturedArticles() {
  return await prisma.article_header
    .findMany({
      select: {
        article_id: true,
        name: true,
        status: true,
        top_img_m: true,
        session_count: true,
        like_count: true,
        disclose_scope: true,
        create_date: true,
        update_date: true,
        user_article_header_author_idTouser: true,
        article_tag_connection: {
          select: {
            tag: true,
          },
        },
        article_page: {
          select: {
            like_count: true,
          },
        },
        article_comments: {
          select: {
            id: true,
          },
        },
      },
      where: {
        AND: {
          featured: {
            not: 0,
          },
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      orderBy: {
        featured: 'asc',
      },
    })
    .then((articles) =>
      articles.map((article) => ({
        ...article,
        commentCount: article.article_comments.length,
      })),
    );
}

/**
 * 週間スライドランキングのコンテンツ一覧を取得
 */
async function findWeeklyRankingArticles(isMobile: boolean) {
  const lastMonday = date.getMondayOfLastWeekJst(new Date());

  return await prisma.article_page_view_weekly_ranking
    .findMany({
      take: isMobile ? MAX_ARTICLE_COUNT_WHEN_MOBILE : undefined,
      where: {
        start_date: lastMonday,
        article_header: {
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      orderBy: {
        count: 'desc',
      },
      include: {
        article_header: {
          select: {
            article_id: true,
            name: true,
            description: true,
            status: true,
            top_img_m: true,
            session_count: true,
            like_count: true,
            disclose_scope: true,
            create_date: true,
            update_date: true,
            user_article_header_author_idTouser: true,
            article_tag_connection: {
              select: {
                tag: true,
              },
            },
            article_page: {
              select: {
                like_count: true,
              },
            },
            article_comments: {
              select: {
                id: true,
              },
            },
          },
        },
      },
    })
    .then((rankings) =>
      rankings.map((ranking) => ({
        ...ranking.article_header,
        commentCount: ranking.article_header.article_comments.length,
      })),
    );
}

/**
 * 人気のタグ一覧を取得
 *
 * タグに紐づいたスライド数の多い順に {@link MAX_POPULAR_TAGS_COUNT} 個のタグを返却する
 * 「結節性硬化症」タグを4番目に固定で挿入する
 */
async function findPopularTags(): Promise<Tag[]> {
  const tuberousSclerosisTagId = 3439; // [結節性硬化症]タグ
  const insertIndex = 3;

  const tuberousSclerosisTagSearchResult = await prisma.tag.findUnique({
    select: {
      tag_id: true,
      tag_name: true,
    },
    where: {
      tag_id: tuberousSclerosisTagId,
    },
  });
  const tuberousSclerosisTag = tuberousSclerosisTagSearchResult
    ? {
        id: tuberousSclerosisTagSearchResult.tag_id,
        name: tuberousSclerosisTagSearchResult.tag_name!,
      }
    : null;

  const searchResult = await prisma.tag
    .findMany({
      take: MAX_POPULAR_TAGS_COUNT,
      select: {
        tag_id: true,
        tag_name: true,
      },
      where: {
        tag_name: {
          not: null,
        },
        tag_id: {
          not: tuberousSclerosisTagId,
        },
      },
      orderBy: {
        article_tag_connection: {
          _count: 'desc',
        },
      },
    })
    .then((tags) =>
      tags.map((tag) => ({
        id: tag.tag_id,
        name: tag.tag_name!,
      })),
    );
  if (tuberousSclerosisTag) {
    if (searchResult.length <= insertIndex) {
      searchResult.push(tuberousSclerosisTag);
    } else {
      searchResult.splice(insertIndex, 0, tuberousSclerosisTag);
    }
  }
  return searchResult;
}

/**
 * 今注目の投稿者一覧を取得
 *
 * @todo 1回の集計結果のレコードが9レコード未満の場合同じ投稿者が表示される問題を解決する
 */
async function findPopularAuthors() {
  const departments = await prisma.departments.findMany({
    select: {
      id: true,
      department_name_ja: true,
    },
  });

  const summary = await prisma.author_contents_view_histories_summary.findFirst(
    {
      select: { end_date: true },
      orderBy: [
        {
          end_date: 'desc',
        },
      ],
    },
  );

  const findHospital = async (hospitalId: number) => {
    const hospital = await prisma.hospitals.findUnique({
      where: {
        id: hospitalId,
      },
      select: {
        name: true,
      },
    });
    return hospital ? hospital.name : null;
  };

  const findHospitalByDiscloseId = async (discloseId: string) => {
    const hospital = await prisma.hospitals.findUnique({
      where: {
        disclose_id: discloseId,
      },
      select: {
        name: true,
      },
    });
    return hospital ? hospital.name : null;
  };

  if (!summary) {
    return [];
  }

  return await prisma.author_contents_view_histories_summary
    .findMany({
      include: {
        user: {
          select: {
            user_id: true,
            last_name: true,
            first_name: true,
            hospital_id: true,
            hospital: true,
            department_id: true,
            profile_photo_s3_key: true,
            update_date: true,
            disclose_id: true,
          },
        },
      },
      where: {
        user: {
          is_deleted: false,
        },
        end_date: summary.end_date,
      },
      take: POPULAR_AUTHOR_MAX_FETCH_NUM,
      orderBy: [
        {
          end_date: 'desc',
        },
        {
          view_count: 'desc',
        },
      ],
    })
    .then((summaries) => summaries.map((summary) => summary.user))
    .then(async (users) => {
      // 投稿者がアカウント統合済みであればID基盤側のプロフィール情報を表示させる
      const discloseIds = users
        .map((v) => v.disclose_id ?? '')
        .filter((v) => !!v);
      const idBaseProfileMap = await fetchAccountIntegrationProfiles(
        discloseIds,
      );

      return await Promise.all(
        users.map(async (user) => {
          // アカウント統合済みの場合は、ID基盤側のプロフィール情報を表示させる
          const idBaseProfile = idBaseProfileMap[user.disclose_id ?? ''];

          // アカウント統合完了時はID基盤側のプロフィールに設定されたhospital_idを用いるが
          // hospitalsのテーブルマスタ情報はSlideとID基盤（QA）で等しいため
          // Slide DBから取得する
          let hospitalName: string | null = null;
          if (idBaseProfile?.hospitalId) {
            hospitalName = await findHospitalByDiscloseId(
              idBaseProfile.hospitalId,
            );
          }
          hospitalName ??= idBaseProfile?.hospitalName ?? null;
          // ID基盤側のIDでレコード取得できなかった場合はSlide側のIDでフォールバックさせる
          if (!hospitalName && user.hospital_id) {
            hospitalName = await findHospital(user.hospital_id);
          }
          hospitalName ??= user.hospital;
          // アカウント統合完了時はID基盤側のプロフィールに設定されたdepartment_idを用いるが
          // departmentsのテーブルマスタ情報はSlideとID基盤（QA）で等しいため
          // Slide DBから取得する
          let departmentName: string | undefined = undefined;
          if (idBaseProfile?.departmentId) {
            const departmentId = idBaseProfile.departmentId as number;
            departmentName = departments.find(
              (v) => v.id === departmentId,
            )?.department_name_ja;
          }
          if (!departmentName) {
            departmentName = departments.find(
              (v) => v.id === user.department_id,
            )?.department_name_ja;
          }

          const profilePhotoKey =
            idBaseProfile?.profilePhotoUrl ?? user.profile_photo_s3_key;
          return {
            userId: user.user_id,
            userName: idBaseProfile?.userName ?? null,
            // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
            lastName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.lastName ?? user.last_name,
            firstName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.firstName ?? user.first_name,
            hospital: hospitalName,
            departmentName: departmentName ?? '',
            hasProfileImage: !!profilePhotoKey,
            idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
            updateDateEpochMilliseconds: user.update_date.getTime(),
            antaaAccountType: idBaseProfile?.accountType ?? null,
            isOfficialAccount: idBaseProfile?.isOfficialAccount ?? null,
          };
        }),
      );
    });
}

async function countUserRecentlyViewedArticles(
  userId: number,
): Promise<number> {
  return await prisma.user_contents_view_histories.count({
    where: {
      user_id: userId,
      article_header: {
        status: 'DONE',
        hidden: false,
        is_deleted: false,
      },
    },
  });
}

async function findUserRecentlyViewedArticles(
  userId: number,
): Promise<UserRecentlyViewedArticle[]> {
  return await prisma.user_contents_view_histories
    .findMany({
      where: {
        user_id: userId,
        article_header: {
          status: 'DONE',
          hidden: false,
          is_deleted: false,
        },
      },
      select: {
        article_header: {
          select: {
            article_id: true,
            name: true,
            top_img_m: true,
            update_date: true,
          },
        },
      },
      orderBy: {
        update_date: 'desc',
      },
      take: MIN_VIEW_HISTORY_COUNT,
    })
    .then((histories) =>
      histories.map((history) => ({
        articleId: history.article_header.article_id,
        name: history.article_header.name,
        topImageM: history.article_header.top_img_m,
        updateDateEpochMilliseconds:
          history.article_header.update_date.getTime(),
      })),
    );
}

/**
 * user_access_historiesテーブルに閲覧履歴を記録
 */
async function createUserAccessHistory(
  userId?: number,
  userAgent?: string,
  gaCid?: string,
) {
  await prisma.user_access_histories.create({
    data: {
      user_id: userId,
      ga_cid: gaCid,
      user_agent: userAgent,
      operation: 'index',
    },
  });
}

export const getServerSideProps: GetServerSideProps<PageContext> = withAuth(
  async (context) => {
    const isMobile = isMobileDevice(context.req.headers['user-agent']).any;
    const featuredArticlesPromise = findFeaturedArticles();
    const weeklyRankingArticlesPromise = findWeeklyRankingArticles(isMobile);

    const [featuredArticlesFromDb, weeklyRankingArticlesFromDb] =
      await Promise.all([
        featuredArticlesPromise,
        weeklyRankingArticlesPromise,
      ]);

    // 投稿者がアカウント統合済みであればID基盤側のプロフィール情報を表示させる
    let discloseIds = featuredArticlesFromDb
      .map((v) => v.user_article_header_author_idTouser.disclose_id ?? '')
      .filter((v) => !!v);
    discloseIds = discloseIds.concat(
      weeklyRankingArticlesFromDb
        .map((v) => v.user_article_header_author_idTouser.disclose_id ?? '')
        .filter((v) => !!v),
    );
    const idBaseProfileMap = await fetchAccountIntegrationProfiles(discloseIds);

    const featuredArticles = featuredArticlesFromDb.reduce(
      (articles: any, article: any) => {
        const author = article.user_article_header_author_idTouser;
        const idBaseProfile = idBaseProfileMap[author.disclose_id ?? ''];

        if (
          shouldNotShow(
            idBaseProfile,
            context.req.user,
            context.req.isAuthenticated(),
          )
        ) {
          return articles;
        }

        articles.push({
          articleId: article.article_id,
          name: article.name,
          status: article.status as ArticleStatus | null,
          topImageM: article.top_img_m,
          sessionCount: article.session_count || 0,
          likeCount:
            article.like_count +
            article.article_page.reduce(
              (prev: any, curr: any) => prev + curr.like_count,
              0,
            ),
          commentCount: article.commentCount,
          discloseScope: article.disclose_scope as DiscloseScope | null,
          createDate: date.toIso8601Jst(article.create_date),
          updateDate: date.toIso8601Jst(article.update_date),
          author: {
            userId: article.user_article_header_author_idTouser.user_id,
            userName: idBaseProfile?.userName ?? null,
            // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
            lastName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.lastName ?? author.last_name,
            firstName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.firstName ?? author.first_name,
            updateDateEpochMilliseconds:
              article.user_article_header_author_idTouser.update_date.getTime(),
            profilePhotoS3Key:
              article.user_article_header_author_idTouser.profile_photo_s3_key,
            idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
            antaaAccountType: idBaseProfile?.accountType ?? null,
            isOfficialAccount: idBaseProfile?.isOfficialAccount ?? null,
          },
          tags: article.article_tag_connection.map((conn: any) => ({
            id: conn.tag.tag_id,
            name: conn.tag.tag_name,
          })),
        });
        return articles;
      },
      [],
    );

    const weeklyRankingArticles = weeklyRankingArticlesFromDb.reduce(
      (articles: any, article: any) => {
        const author = article.user_article_header_author_idTouser;
        const idBaseProfile = idBaseProfileMap[author.disclose_id ?? ''];

        if (
          shouldNotShow(
            idBaseProfile,
            context.req.user,
            context.req.isAuthenticated(),
          )
        ) {
          return articles;
        }

        articles.push({
          articleId: article.article_id,
          name: article.name,
          description: article.description,
          status: article.status as ArticleStatus | null,
          topImageM: article.top_img_m,
          sessionCount: article.session_count || 0,
          likeCount:
            article.like_count +
            article.article_page.reduce(
              (prev: any, curr: any) => prev + curr.like_count,
              0,
            ),
          commentCount: article.commentCount,
          discloseScope: article.disclose_scope as DiscloseScope | null,
          createDate: date.toIso8601Jst(article.create_date),
          updateDate: date.toIso8601Jst(article.update_date),
          author: {
            userId: article.user_article_header_author_idTouser.user_id,
            userName: idBaseProfile?.userName ?? null,
            // userNameが設定されている場合はfirstName, lastNameはフロントに返さないようにする
            lastName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.lastName ??
                article.user_article_header_author_idTouser.last_name,
            firstName: idBaseProfile?.userName
              ? null
              : idBaseProfile?.firstName ??
                article.user_article_header_author_idTouser.first_name,
            updateDateEpochMilliseconds:
              article.user_article_header_author_idTouser.update_date.getTime(),
            profilePhotoS3Key:
              article.user_article_header_author_idTouser.profile_photo_s3_key,
            idBaseProfileUrl: idBaseProfile?.profilePhotoUrl ?? null,
            antaaAccountType: idBaseProfile?.accountType ?? null,
            isOfficialAccount: idBaseProfile?.isOfficialAccount ?? null,
          },
          tags: article.article_tag_connection.map((conn: any) => ({
            id: conn.tag.tag_id,
            name: conn.tag.tag_name,
          })),
        });
        return articles;
      },
      [],
    );

    const [popularTags, popularAuthors] = await Promise.all([
      findPopularTags(),
      findPopularAuthors(),
    ]);

    let userViewedArticleCount = null;
    let userRecentlyViewedArticles = null;
    if (context.req.isAuthenticated()) {
      userViewedArticleCount = await countUserRecentlyViewedArticles(
        context.req.user.id,
      );
      userRecentlyViewedArticles = await findUserRecentlyViewedArticles(
        context.req.user.id,
      );
    }

    const userId = context.req.user?.id;
    const userAgent = context.req ? context.req.headers['user-agent'] : '';
    createUserAccessHistory(userId, userAgent, getGaCid(context.req));

    return {
      props: {
        featuredArticles,
        weeklyRankingArticles,
        popularTags,
        popularAuthors,
        userViewedArticleCount,
        userRecentlyViewedArticles,
      },
    };
  },
);
